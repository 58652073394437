<style>
    #inputPhone {
        width: 195px;
        margin-right: 8px;
    }

    .btn_phone {
        background-color: #40868f;
        color: #fff;
        padding: 1em;
        margin: 1px;
        display: inline-block;
        vertical-align: top;
    }

    .el-dialog {
        width: 500px;
    }

    .el-input {
        width: 90%;
    }

    .refresh {
        position: relative;
        font-size: 30px;
        top: 6px;
        left: 10px
    }

    @media(max-width:540px) {
        .el-dialog {
            width: 90%;
        }
    }
</style>
<template>
    <div>
        <article class="page inquire">
            <div class="content-con">
                <h2 class="page-tit"><em>注册</em></h2>

                <section class="forms-basic inquire-form">
                    <input type="text" v-model="RegisterViewModel.Name" id="inputName" placeholder="请输入姓名" required />
                    <input type="number" v-model="RegisterViewModel.Mobile" id="inputPhone" class="d-inline-block" placeholder="请输入手机号" required />
                    <a class="btn_phone" @click="dialogVisible = true">发送验证码</a>
                    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
                        <el-image style="width: 100%; margin-bottom:10px"
                                  :src="require('@/assets/image/captcha.png')">
                        </el-image>
                        <!--<img src="../assets/image/capcha.png" />-->
                        <el-input v-model="captcha" id="inputCaptcha" class="d-inline-block"
                                  placeholder="请输入上图所显示的数字">
                        </el-input>
                        <a class="refresh"><i class="el-icon-refresh"></i></a>
                        <span slot="footer" class="dialog-footer">
                            <a class="btn-active" v-on:click="SendTextMessage">送出验证码简讯</a>
                        </span>
                    </el-dialog>
                    <input type="text" v-model="RegisterViewModel.ValidationCode" id="inputVerifyCode" placeholder="请输入简讯中的数字" required />
                    <input type="password" v-model="RegisterViewModel.Password" id="inputPassword" placeholder="请输入密码" required />
                    <input type="password" v-model="RegisterViewModel.PasswordConfirm" id="inputPasswordConfirm" placeholder="请再输入一次密码" required />
                    <p class="inquire-action"><a class="btn-active" @click="Register()">注册</a></p>
                </section>

            </div>
        </article>
    </div>
</template>

<script>
    import {
        SendRegisterValidationCode,
        Register
    } from "@/service/api"
    export default {
        data() {
            return {
                dialogVisible: false,
                code: "",
                captcha: "",
                RegisterViewModel: {
                    "Merchant": location.hostname,
                    "Name": "",
                    "Mobile": "",
                    "Password": "",
                    "PasswordConfirm": "",
                    "ValidationCode": ""
                },
            };
        },
        methods: {
            SendTextMessage() {
                //console.log(this.RegisterViewModel.Mobile);
                if (this.captcha === undefined || this.captcha === '') {
                    this.$notify({ title: "警告", message: "验证码必填", type: 'warning' });
                    return;
                }
                if (this.RegisterViewModel.Mobile === undefined || this.RegisterViewModel.Mobile === '') {
                    this.$notify({ title: "警告", message: "手机号必填", type: 'warning' });
                    return;
                }
                let data = { "Merchant": this.RegisterViewModel.Merchant, "Mobile": this.RegisterViewModel.Mobile };
                SendRegisterValidationCode(data)
                    .then((response) => {
                        console.log(response);
                        this.code = response.data;
                        this.$notify({ title: "成功", message: "已发送简讯至您所填写的手机", type: 'success' });
                    })
                    .catch(error => {
                        //console.log(error);
                        //console.log(error.data);
                        let errorMessage = '';
                        if (error?.data?.ErrorMessage?.length > 0) {
                            errorMessage = error?.data?.ErrorMessage;
                        }
                        this.$notify({ title: "错误", message: errorMessage, type: 'error' });
                    });
            },
            Register() {
                //console.log(this.RegisterViewModel);
                if (this.RegisterViewModel.Name === undefined || this.RegisterViewModel.Name === '') {
                    this.$notify({ title: "警告", message: "姓名必填", type: 'warning' });
                    return;
                }
                if (this.RegisterViewModel.Mobile === undefined || this.RegisterViewModel.Mobile === '') {
                    this.$notify({ title: "警告", message: "手机号必填", type: 'warning' });
                    return;
                }
                if (this.RegisterViewModel.ValidationCode === undefined || this.RegisterViewModel.ValidationCode === '') {
                    this.$notify({ title: "警告", message: "简讯验证码必填", type: 'warning' });
                    return;
                }
                if (this.RegisterViewModel.ValidationCode !== this.code) {
                    this.$notify({ title: "警告", message: "简讯验证码不相符", type: 'warning' });
                    return;
                }
                if (this.RegisterViewModel.Password === undefined || this.RegisterViewModel.Password === '') {
                    this.$notify({ title: "警告", message: "密码必填", type: 'warning' });
                    return;
                }
                if (this.RegisterViewModel.PasswordConfirm === undefined || this.RegisterViewModel.PasswordConfirm === '') {
                    this.$notify({ title: "警告", message: "确认密码必填", type: 'warning' });
                    return;
                }
                if (this.RegisterViewModel.Password !== this.RegisterViewModel.PasswordConfirm) {
                    this.$notify({ title: "警告", message: "密码与确认密码不相同", type: 'warning' });
                    return;
                }
                Register(this.RegisterViewModel)
                    .then((response) => {
                        //console.log(response);
                        this.$notify({ title: "成功", message: "您已注册成功", type: 'success' });
                        let username = response.data.Name;
                        localStorage.setItem("username", username);
                        localStorage.setItem("isLogin", true);
                        location.href = location.origin;
                    })
                    .catch(error => {
                        //console.log(error);
                        //console.log(error.data);
                        let errorMessage = '';
                        if (error?.data?.ErrorMessage?.length > 0) {
                            errorMessage = error?.data?.ErrorMessage;
                        }
                        this.$notify({ title: "错误", message: errorMessage, type: 'error' });
                    });
            }
        }
    };
</script>
